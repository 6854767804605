.ModalContainer {
    background: rgba(34, 34, 34, 0.377);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
}

.ModalContainer .Modal {
    border: 1px solid rgb(36, 35, 35);
    width: 50vw;
    height: 50vh;
    position: relative;
    top: 20vh;
    left: 25vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ModalContainer.hideModal {
    display: none;
}
