.LiftersProductContainer {
    font-family: 'Roboto', sans-serif;
}

.LiftersProductContainer .banner {
    background-image: url('/public/lifters-product-banner-image.png');
    width: 98.6vw;
    height: 56vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: 9vh;
}

.LiftersProductContainer .title-container {
    width: 50vw;
    height: 149px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 5vh;
}

.LiftersProductContainer .title-container .title {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: 60px;
    left: 10VW;
    top: 25vh;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
}

.LiftersProductContainer .title-container .outline {
    position: relative;
    height: 60px;
    left: 10VW;
    top: 10vh;
}

.HorizontalFeaturesContainer {
    position: relative;
    top: 10vh;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin-bottom: 10vh;
}

.VerticalFeatures {
    background-color: #181818;
    width: 80vw;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    position: relative;
    top: 15vh;
}

.HorizontalFeatures {
    background-color: #181818;
    width: 40vw;
    height: 82vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 10vh;
}

.VerticalFeatures .featureBlock, .HorizontalFeatures .featureBlock {
    margin: 4%;
    display: grid;
    grid-template-columns: 40% 45%;
    gap: 4vw;
}

.HorizontalFeatures .featureBlock {
    grid-template-rows: 40% 45%;
    grid-template-columns: none;
    margin: 3%;
}

.VerticalFeatures .featureBlock .header, .HorizontalFeatures .featureBlock .header {
    border-bottom: 3px solid red;
    font-size: 27.9px;
}

.HorizontalFeatures .featureBlock .header {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.VerticalFeatures .featureBlock .featureBlock-desc, .HorizontalFeatures .featureBlock .featureBlock-desc {
    font-size: 20px;
    position: relative;
    bottom: 2vh;
}

.featureBlock-Icon {
    color: red;
}

.Subs-Containers {
    width: 60vw;
    position: relative;
    top: 15vh;
    left: 3vw;
    display: flex;
    flex-direction: row;
    gap: 2vw;
}

.Subs {
    background-color: #181818;
    height: 55vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.Subs .sub-header {
    height: 23vh;
    width: 30vw;
    background: black;
}

.Subs .sub-header .wrapper {
    margin: 10%;
}

.Subs .sub-header.red {
    background: rgb(248, 33, 33);
}

.Subs .sub-header .wrapper .sub-title:nth-child(1) {
    margin-bottom: 4vh;
}

.Subs .sub-header .wrapper .sub-title {
    font-size: 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Subs .sub-header .wrapper .sub-title .sub-price {
    font-size: 20px;
}

.Subs .sub-header .wrapper .sub-title .sub-price-month {
    font-size: 12px;
    margin-left: 12px;
}

.Subs .sub-header .wrapper .sub-desc {
    border-top: 1px solid white;
    font-size: 12px;
}

.Subs .sub-header .wrapper .sub-desc div {
    margin-top: 10px;
}

.Subs .sub-features {
    width: 30vw;
    height: 100vh;
    position: relative;
    top: 1.5vh;
    left: 1vw;
}

.Subs .sub-features .sub-features-list {
    list-style-type: none;
    width: 26vw;
}

.Subs .sub-features .sub-features-list li:before {
    content: "-";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    margin-left: -1em;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.Subs .sub-warning-small {
    font-size: 15px;
    color:rgb(61, 61, 61);
    margin: 4%;
    position: relative;
    top: -2.5vh;
}

.LiftersProductContainer .FooterContainer {
    position: relative;
    top: 20vh;
}
