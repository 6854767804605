.Header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 26px 80px;
    gap: 10px;
    position: absolute;
    width: 98.7vw;
    height: 80px;
    left: 0px;
    top: 0px;
    border: 1px solid #161616;
}

.Header .Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 799px;
    width: 90vw;
    height: 28px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.Header .Wrapper .Logo {
    width: 64px;
    height: 28px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.Header .Wrapper .Menu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 25vw;
    height: 18px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.Header .Wrapper .Menu .Item {
    width: 59px;
    height: 18px;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.Header .Wrapper .Menu .Item:hover {
    color: #FF3636;
    background: white;
    padding: 2px;
    border-radius: 10px;
}

.Header .Wrapper .Menu .Red {
    color: #FF3636;
}

.Header .Wrapper .Menu .active {
    text-decoration: underline;
    text-decoration-color: #FF3636;
    text-decoration-thickness: 2px;
}
