.FoodAPIContainer .product-banner-background {
    position: relative;
    top: 10vh;
    height: 60vh;
}

.FoodAPIContainer .product-banner-background .img {
    width: 80vw;
    height: 60vh;
    margin-right: auto;
    margin-left: auto;
    background-image: url("/public/food-api-product-page-banner.png");
    background-size: 80vw 60vh;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    top: 7vh;
}

.FoodAPIContainer .product-banner-background .product-banner-red-door {
    background: red;
    width: 50vw;
    height: 50vh;
    margin-right: auto;
    margin-left: auto;
    border-radius: 500px 500px 0px 0px;
    position: relative;
    top: -50vh;
    z-index: -1;
    border: 1px solid white;
}

.FoodAPIContainer .product-banner-background .fade-overlay {
    position: relative;
    width: 20vw;
    height: 99vw;
    left: 28vw;
    bottom: 82vw;
    background: linear-gradient(90deg, #050505 14.19%, rgba(5, 5, 5, 0) 104.09%);
    transform: rotate(-90deg);
    z-index: -1;
}

.FoodAPIContainer .title-container .title {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    height: 60px;
    left: 10VW;
    top: 20vh;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
}

.FoodAPIContainer .title-container .outline {
    position: relative;
    height: 60px;
    left: 10VW;
    top: 5vh;
}

.FoodAPIContainer .Subs-Containers {
    margin-right: auto;
    margin-left: auto;
}

.FoodAPIContainer .Subs {
    height: 40vh;
    margin-bottom: 12vh;
    margin-right: auto;
    margin-left: auto;
}

.FoodAPIContainer .api-modules {
    position: relative;
    top: 16vh;
    width: 67vw;
    height: 22vh;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.FoodAPIContainer .api-modules .api-module {
    background: #181818;
    width: 32vw;
    height: 20.5vh;
    padding: 10px;
}

.FoodAPIContainer .api-modules .api-module .wrapper {
    margin: 7%;
    font-size: 35px;
}

.FoodAPIContainer .FooterContainer {
    position: relative;
    top: 30vh;
}
