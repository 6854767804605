.FooterContainer {
    box-sizing: border-box;
    position: absolute;
    height: 45vh;
    width: 98.7vw;
    left: 0px;
    bottom: 0;
    background: #000000;
    border-top: 1px solid #161616;
}

.FooterContainer .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 1.5vh;
    position: relative;
    width: 15vw;
    height: 30vh;
    left: 2vw;
    top: 5vh;
}

.FooterContainer .info .logo {
    width: 3.5vw;
    height: 5vh;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterContainer .info .title {
    width: 158px;
    height: 48px;
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.09em;
    color: #FFFFFF;
}

.FooterContainer .info .paragraph {
    width: 18vw;
    height: 10vh;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #919191;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.FooterContainer .columns {
    width: 76vw;
    height: 20vh;
    display: flex;
    flex-direction: row;
    gap: 10vw;
    position: relative;
    bottom: 25vh;
    left: 20vw;
}

.FooterContainer .columns .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
}

.FooterContainer .columns .column .title {
    width: 137px;
    height: 25px;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;
    color: #FF3636;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterContainer .columns .column .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

.FooterContainer .columns .column .menu a {
    text-decoration: none;
    color: white;
}

.FooterContainer .copyright {
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    left: 38vw;
    top: -17vh;
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    color: #565656;
}

.FooterContainer .button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    position: relative;
    width: 10vw;
    height: 40px;
    left: 75vw;
    top: -22vh;
}

.FooterContainer .button-group .app-store-badge {
    box-sizing: border-box;
    width: 135px;
    height: 40px;
    background: #000000;
    mix-blend-mode: normal;
    border: 1px solid #A6A6A6;
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.FooterContainer .button-group .app-store-badge .google-logo {
    position: relative;
    top: 15%;
    left: 5%;
}

.FooterContainer .button-group .app-store-badge .get-it-on {
    position: relative;
    bottom: 70%;
    left: 30%;
    font-size: 10px;
    width: -moz-fit-content;
    width: fit-content;
}

.FooterContainer .button-group .app-store-badge .google-play-text {
    position: relative;
    bottom: 68%;
    left: 30%;
}
