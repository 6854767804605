.loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    align-items: center;
    background: black;
    z-index: 20;
}

.lottie-loading {
    width: 50vw;
    height: 50vh;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 20vh;
}
