.FiidApiSetUpPayments .StripeElement {
    position: relative;
    top: 10vh;
    width: 60vw;
    border: 1px solid #222212;
    border-radius: 10px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.FiidApiSetUpPayments .StripeElement .Input {
    background-color: #222212;
}

.FiidApiSetUpPayments .submitPayment {
    position: relative;
    top: 10vh;
    width: 20vw;
    border: 1px solid #FF3636;
    border-radius: 10px;
    padding: 10px;
    background-color: #FF3636;
    color: #fff;
    font-size: 20px;
    position: relative;
    top: 15vh;
    left: 40vw;
}
